import { useState } from 'react'

import { captureEvent } from '@sentry/browser'
import { PromisePool } from '@supercharge/promise-pool'
import {
  type CreateMembershipForUserIdInput,
  type CreateUserWithMembershipMutation,
  type CreateUserWithMembershipMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import type { Membership } from 'src/components/MemberManagementCell'
import { useAuth } from 'src/Providers'

import type { BulkCreateUser } from '../BulkUserCreate/BulkUserCreateModal'

export type ExistingUser = { id: string; name: string; email: string }

export interface BulkUserImportError {
  id: string
  email: string
  error: { message: string; code?: string }
}

const CREATE_USER_WITH_MEMBERSHIP = gql`
  mutation CreateUserWithMembershipMutation(
    $inviteUser: Boolean
    $user: CreateUserInput!
    $membership: CreateMembershipForUserIdInput!
    $membershipGroupIds: [Int!]!
    $memberPositions: [Int!]
  ) {
    createUserWithMembership(
      inviteUser: $inviteUser
      user: $user
      membership: $membership
      membershipGroupIds: $membershipGroupIds
      memberPositions: $memberPositions
    ) {
      user {
        id
      }
      membership {
        id
      }
    }
  }
`

export const useUpsertBulkUsersWithMembership = (
  memberships: Membership[],
  updateRowStatus: (id: string, newStatus: string) => void,
  setProcessedPercentage: (percentage: number) => void,
) => {
  const { currentUser } = useAuth()
  const [errors, setErrors] = useState<BulkUserImportError[]>([])
  const [isProcessing, setIsProcessing] = useState(false)

  const [createUserWithMembership] = useMutation<
    CreateUserWithMembershipMutation,
    CreateUserWithMembershipMutationVariables
  >(CREATE_USER_WITH_MEMBERSHIP)

  const upsertBulkUsersWithMembership = async (
    usersToCreate: BulkCreateUser[],
  ) => {
    const batchSize = 5
    setIsProcessing(true)

    // enrich each user with membership data
    const enrichedUsers = usersToCreate.map((newUser) => {
      const id = newUser.id
      const user = {
        id: undefined,
        email: newUser.email,
        name: newUser.name,
        position: newUser.position,
      }

      const isSuperAdmin = currentUser.roles.includes('SUPERADMIN')

      const membership = {
        role:
          newUser.role === 'OWNER' && !isSuperAdmin ? undefined : newUser.role,
        clientId: currentUser.membershipData.clientId,
        membershipType: 'CLIENT',
        position: newUser.position,
        reportsToId: null,
      } as CreateMembershipForUserIdInput

      return { id, user, membership }
    })

    // Reset state before starting processing
    setErrors([])
    let numberofErrors = 0

    await PromisePool.withConcurrency(batchSize)
      .for(enrichedUsers)
      .handleError((error, user, pool) => {
        numberofErrors++
        if (numberofErrors > 5) {
          pool.stop()
          captureEvent({
            message:
              'Too many errors in bulk user creation, stopping processing',
            extra: { error },
          })
        }
        setErrors((prev) => [
          ...prev,
          { id: user.id, email: user.user.email, error },
        ])
        updateRowStatus(user.id, 'error')
      })
      .onTaskStarted((user, pool) => {
        setProcessedPercentage(pool.processedPercentage())
        updateRowStatus(user.id, 'processing')
      })
      .process(async (user) => {
        const exists = memberships.some(
          (membership) => membership.user.email === user.user.email,
        )
        if (exists) {
          // Await the promise so we can update the row status - otherwise the UI will not update
          await Promise.resolve().then(() => {
            updateRowStatus(user.id, 'skipped')
            return { id: user.id, createStatus: 'skipped' }
          })
        } else {
          await createUserWithMembership({
            variables: {
              inviteUser: false,
              user: user.user,
              membership: user.membership,
              membershipGroupIds: [],
              memberPositions: [],
            },
          })
          updateRowStatus(user.id, 'success')
          return { id: user.id, createStatus: 'success' }
        }
      })
    setIsProcessing(false)
  }

  return { upsertBulkUsersWithMembership, isProcessing, errors }
}
