import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import { isEmailValid } from 'api/src/common/utils'
import Select from 'react-select'

import { toast } from '@redwoodjs/web/toast'

import InformerOrgChartUpsertSeatModal from 'src/components/InformerOrgChart/InformerOrgChartUpsertSeatModal'
import { useUpsertUserWithMembership } from 'src/components/MemberManagement/hooks/useUpsertUserWithMembership'
import { ItemDialogData } from 'src/components/MemberManagement/UserDataTypes'
import {
  Membership,
  MembershipGroup,
} from 'src/components/MemberManagementCell'
import Modal from 'src/components/Modal/Modal'
import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { validatePhone } from 'src/lib/phoneValidator'
import { useAuth } from 'src/Providers'

export type MemberPositionData = {
  id: number
  name: string
}

type MemberPositionOptionsType = {
  label: ReactElement
  value: string | number
}

interface MemberManagementFormProps {
  member: any
  members: Membership[]
  isOpen: boolean
  memberPositionList: MemberPositionData[]
  groupList: MembershipGroup[]
  onClose: () => void
}

const getEmailStatus = (email = '') => {
  if (email) {
    const isValid = isEmailValid(email)

    return {
      isValid,
      message: `(${isValid ? 'Valid' : 'Invalid'} Email)`,
    }
  }

  return {
    isValid: false,
    message: '(Invalid Email)',
  }
}

const FormInputRow = ({ label, fieldId, children }) => {
  return (
    <div className="flex w-full flex-wrap gap-4">
      <div className="w-full grow">
        <label htmlFor={`#${fieldId}`} className="block pb-1 text-gray-600">
          {label}
        </label>
        <div className="flex items-start gap-2">
          <div className="grow">{children}</div>
        </div>
      </div>
    </div>
  )
}

const MemberManagementForm: FC<MemberManagementFormProps> = ({
  member,
  isOpen,
  memberPositionList,
  onClose,
  groupList,
  members,
}) => {
  const dataGroups = []
  const dataReportsTo = []
  if (member?.reportsToId) {
    dataReportsTo.push({
      label: member.reportsToName,
      value: member.reportsToId,
    })
  }

  const { upsertUserWithMembership } = useUpsertUserWithMembership()

  if (member?.membershipGroups) {
    for (const item of member.membershipGroups) {
      dataGroups.push({
        label: item.membershipGroup.name,
        value: item.membershipGroup.id,
      })
    }
  }

  const dataMemberPosition: { label: string; value: number }[] = []
  const groupOptions = groupList.map((group) => {
    return {
      label: group.name,
      value: group.id,
    }
  })
  if (member?.memberPositions) {
    for (const item of member.memberPositions) {
      dataMemberPosition.push({
        label: item.name,
        value: item.id,
      })
    }
  }
  const reportsToOptions = members.map((member) => {
    return {
      label: member.user.name,
      value: member.id,
    }
  })

  const { currentUser } = useAuth()
  const [userPosition, setUserPosition] = useState(member?.position)
  const [userInvited, setUserInvited] = useState(false)
  const [userGroups, setUserGroups] = useState(dataGroups)
  const [loading, setLoading] = useState(false)
  const [itemRole, setItemRole] = useState(
    member?.role
      ? {
          label: member.role,
          value: member.role,
        }
      : {
          label: 'MEMBER',
          value: 'MEMBER',
        },
  )
  const [userMemberPosition, setUserMemberPosition] =
    useState(dataMemberPosition)
  const [userName, setUserName] = useState<string>(member?.name)
  const [userEmail, setUserEmail] = useState<string>(member?.email)
  const [userPhone, setUserPhone] = useState<string>(member?.phone)
  const [phoneStatus, setPhoneStatus] = useState(
    // NOT USED - commented out because it is not implemented properly. @sam or @will come back to this
    member?.phone
      ? validatePhone(member?.phone)
      : { isValid: true, message: '' },
  )
  const [emailStatus, setEmailStatus] = useState(
    member?.email
      ? getEmailStatus(member?.email)
      : { isValid: true, message: '' },
  )
  const clientId = useMemo(() => currentUser.parentData.id, [currentUser])
  const [avatarUrl, setAvatarUrl] = useState(member?.avatarUrl)
  const [avatarStorageObject, setAvatarStorageObject] = useState(
    member?.avatarStorageObject,
  )
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [openUpsertRoleModal, setOpenUpsertRoleModal] = useState(false)
  const [memberPositionsOptions, setMemberPositionsOptions] = useState<
    MemberPositionOptionsType[]
  >([])

  const [userReportsTo, setUserReportsTo] = useState<{
    label: string
    value: number
  }>(dataReportsTo[0])
  // Role Options
  const isSuperAdmin = currentUser.roles.includes('SUPERADMIN')
  let roleOptions = []
  if (member?.role !== 'OWNER' || isSuperAdmin) {
    roleOptions = [
      {
        label: 'ADMIN',
        value: 'ADMIN',
      },
      {
        label: 'EDITOR',
        value: 'EDITOR',
      },
      {
        label: 'MEMBER',
        value: 'MEMBER',
      },
    ]
  }

  if (isSuperAdmin) {
    roleOptions.push({
      label: 'OWNER',
      value: 'OWNER',
    })
  }

  // initial load
  useEffect(() => {
    const initialDataMemberPosition = memberPositionList
      ? memberPositionList.map((memberPosition) => {
          return {
            label: <>{memberPosition.name}</>,
            value: memberPosition.id,
          }
        })
      : []

    setMemberPositionsOptions([
      ...initialDataMemberPosition,
      {
        label: (
          <Stack
            direction="row"
            spacing={2}
            alignContent={'center'}
            alignItems={'center'}
          >
            <PlusIcon className="h-4 w-4 text-green-600" />
            Add New
          </Stack>
        ),
        value: 'add_new',
      },
    ])
  }, [])

  // email validation
  const setUserEmailCheck = (email: string) => {
    setEmailStatus(getEmailStatus(email))
    setUserEmail(email)
  }

  // phone validation
  const setUserPhoneCheck = (phone: string) => {
    setPhoneStatus(validatePhone(phone))
    setUserPhone(phone)
  }

  // Avatar upload logic
  const [
    uploadAvatar,
    { result: avatarResult, error: avatarError, loading: avatarUploading },
  ] = useUploadStorageObject({
    prefixes: [`${clientId}`, 'user', 'profile'],
    fileName: 'avatar',
    allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
  })

  useEffect(() => {
    avatarResult && setAvatarStorageObject(avatarResult)
    if (avatarResult) {
      setAvatarUrl(avatarResult.downloadUrl)
      setAvatarLoading(false)
    }
  }, [avatarResult])

  useEffect(() => {
    avatarError && toast.error(avatarError.message)
  }, [avatarError])

  const uploadAvatarHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length === 0) {
        toast.error('You must select an image to upload.')
        return
      }
      setAvatarLoading(true)

      const file: File = event.target.files[0]
      uploadAvatar({ file })
    },
    [uploadAvatar],
  )

  const checkUserExists = (email: string) => {
    if (!email) return false
    return members.some(
      (member) =>
        member.user.email.toLowerCase().trim() === email.toLowerCase().trim(),
    )
  }

  const isRoleChangeDisabled = (): boolean => {
    //   if current user is superadmin - this is a complete override for top level admins - they can change roles
    if (isSuperAdmin) return false
    //   if current user is alias admin - this should not allow role change
    if (currentUser.isClientAlias && member) return true
    //   if member is owner - this should not allow role change
    if (itemRole.value === 'OWNER') return true

    return false
  }

  const roleChangeDisabled = isRoleChangeDisabled()

  return (
    <>
      <Modal
        dialogClassName={'max-w-[700px]'}
        confirmText={'Save'}
        open={isOpen}
        title={member ? 'Update User' : 'Create User'}
        footerVisible
        loading={loading}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={async () => {
          setLoading(true)
          if (!member && checkUserExists(userEmail)) {
            setLoading(false)
            toast.error(
              'Member already exists, check your member list and deactivated members list',
              {
                duration: 5000,
              },
            )
            return
          }
          const data: ItemDialogData = {
            id: member?.id || undefined,
            userId: member?.user?.id || undefined,
            name: userName,
            email: userEmail,
            phone: userPhone,
            position: userPosition,
            avatarUrl,
            avatarStorageObject,
            avatarStorageObjectId: avatarStorageObject?.id,
            reportsToId: member?.reportsTo?.id || undefined,
            reportsTo: userReportsTo?.value,
            role: itemRole?.value,
            membershipGroups: userGroups?.map((item) => item.value),
            memberPosition: userMemberPosition?.map((item) => item.value),
            inviteUser: userInvited,
          }
          await upsertUserWithMembership({
            data,
          })
            .then(() => {
              setLoading(false)
              toast.success('User saved')
              onClose()
            })
            .catch((error) => {
              setLoading(false)
              toast.error(error.message)
            })
        }}
      >
        <div className={'flex flex-col p-8'}>
          {/*  banner for seats */}
          <Stack spacing={3} justifyContent="center">
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box sx={{ position: 'relative' }}>
                <Avatar
                  className={'h-20 w-20'}
                  alt={member?.name}
                  src={avatarUrl}
                />
                {avatarLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: '#4f46e5',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              <FormInputRow
                label="Upload profile picture"
                fieldId="member-photo"
              >
                <div className="grow">
                  <input
                    id="member-photo"
                    type="file"
                    accept="image/*, video/*"
                    onChange={(e) => uploadAvatarHandler(e)}
                    className="w-full rounded-[4px] border border-gray-400 bg-white p-3 text-sm font-medium leading-4 text-gray-700 hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  />
                </div>
              </FormInputRow>
            </Stack>

            <FormInputRow label="Name" fieldId="member-name">
              <TextField
                required
                value={userName}
                fullWidth
                placeholder="Full Name.. "
                id="member-name"
                data-testid="member-name-input"
                className="w-full bg-white"
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
              />
            </FormInputRow>

            <FormInputRow label="Email" fieldId="member-email">
              <TextField
                required
                id="member-email"
                placeholder="Email Address.."
                value={userEmail}
                disabled={member?.email}
                fullWidth
                onChange={(e) => {
                  setUserEmailCheck(e.target.value)
                }}
                error={!emailStatus.isValid || checkUserExists(userEmail)}
              />
              <div className={'text-red-500'}>
                {!emailStatus.isValid && emailStatus.message}
                {checkUserExists(userEmail) && !member && (
                  <span className="text-red-500"> (Member already exists)</span>
                )}
              </div>
            </FormInputRow>

            <FormInputRow label="Phone" fieldId="member-phone">
              <TextField
                id="member-phone"
                value={userPhone}
                placeholder="Phone Number.."
                fullWidth
                onChange={(e) => {
                  setUserPhoneCheck(e.target.value)
                }}
              />
            </FormInputRow>

            {/*Leave in for now as we may want to add this back in later if Bryn changes his mind again*/}
            {/*{orgChartActive && (*/}
            {/*  <FormInputRow label="Org Position" fieldId="memberPosition">*/}
            {/*    <Select*/}
            {/*      id="memberPosition"*/}
            {/*      name="memberPosition"*/}
            {/*      isMulti*/}
            {/*      // @ts-expect-error string to react element*/}
            {/*      options={memberPositionsOptions}*/}
            {/*      placeholder="Select Position"*/}
            {/*      value={userMemberPosition}*/}
            {/*      onChange={(event) => {*/}
            {/*        if (*/}
            {/*          event &&*/}
            {/*          event.some((item) => item.value.toString() === 'add_new')*/}
            {/*        ) {*/}
            {/*          setOpenUpsertRoleModal(true)*/}
            {/*        } else {*/}
            {/*          setUserMemberPosition(*/}
            {/*            event as { label: string; value: number }[],*/}
            {/*          )*/}
            {/*        }*/}
            {/*      }}*/}
            {/*      className="border-gray-300 sm:text-sm rounded-md z-50"*/}
            {/*      classNames={{*/}
            {/*        control: () => 'p-2',*/}
            {/*        menu: () => 'z-50',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </FormInputRow>*/}
            {/*)}*/}

            <FormInputRow label="Position" fieldId="member-position">
              <TextField
                id="member-position"
                data-testid="member-position-input"
                value={userPosition}
                placeholder="Position.."
                fullWidth
                onChange={(e) => {
                  setUserPosition(e.target.value)
                }}
              />
            </FormInputRow>
            {/*Leave in for now as we may want to add this back in later if Bryn changes his mind again*/}
            {/*<FormInputRow label="Reports To" fieldId="reportsTo">*/}
            {/*  <Select*/}
            {/*    id="reportsTo"*/}
            {/*    name="reportsTo"*/}
            {/*    options={reportsToOptions}*/}
            {/*    placeholder="Reports To"*/}
            {/*    value={userReportsTo}*/}
            {/*    onChange={(event) => setUserReportsTo(event)}*/}
            {/*    className="border-gray-300 sm:text-sm rounded-md"*/}
            {/*    classNames={{*/}
            {/*      control: () => 'p-2',*/}
            {/*      menu: () => 'z-50',*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</FormInputRow>*/}

            <FormInputRow label="Role" fieldId="role">
              <Select
                id="role"
                name="role"
                options={roleOptions}
                isDisabled={roleChangeDisabled}
                placeholder="Select role"
                value={itemRole}
                onChange={(event) => setItemRole(event)}
                className="rounded-md border-gray-300 sm:text-sm"
                classNames={{
                  control: () => 'p-2',
                  menu: () => 'z-50',
                }}
              />
            </FormInputRow>

            <FormInputRow label="Membership Groups" fieldId="membershipGroup">
              <Select
                id="membershipGroup"
                name="membershipGroup"
                isMulti
                options={groupOptions}
                placeholder="Select groups"
                value={userGroups}
                onChange={(event) => {
                  setUserGroups(event as MemberPositionOptionsType[])
                }}
                className="rounded-md border-gray-300 sm:text-sm"
                classNames={{
                  control: () => 'p-2',
                  menu: () => 'z-50',
                }}
              />
            </FormInputRow>

            {/* Hide this if in edit mode OR you are in alias (no initial invite on create fo support mode) */}
            {!member && (isSuperAdmin || !currentUser.isClientAlias) && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setUserInvited(!userInvited)
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 28,
                        color: userInvited ? '#4f46e5' : '#52525b',
                      },
                    }}
                  />
                }
                label={'Invite user to sign up'}
                labelPlacement="start"
                className="m-0 w-full select-none justify-between rounded border border-gray-300 bg-gray-50 p-4 py-2"
              />
            )}
          </Stack>
        </div>
        <InformerOrgChartUpsertSeatModal
          openModal={openUpsertRoleModal}
          setOpenUpsertPositionModal={() => setOpenUpsertRoleModal(false)}
          currentPositions={memberPositionsOptions}
          setCurrentPositions={setMemberPositionsOptions}
        />
      </Modal>
    </>
  )
}

export default MemberManagementForm
