import { isEmailValid } from 'api/src/common/utils'
import Papa from 'papaparse'

import { readFileAsText } from 'src/lib/helpers'

import type { BulkCreateUser } from './BulkUserCreateModal'

const validHeaders = ['Full_Name', 'Email_Address', 'Position', 'Role']
const transformedHeaders = ['name', 'email', 'position', 'role']
// Allow either the original or the already transformed values:
const allowedHeaders = [...validHeaders, ...transformedHeaders]

export async function parseCsvData(csvFile: File): Promise<BulkCreateUser[]> {
  // Await the helper function to read file content as text
  const csvContent = await readFileAsText(csvFile)

  const parsed = Papa.parse<BulkCreateUser>(csvContent, {
    dynamicTyping: true,
    header: true,
    skipEmptyLines: true,
    comments: '#',
    transformHeader: (header) => {
      const trimmedHeader = header.trim()
      if (!trimmedHeader) {
        throw new Error(
          `Blank header found. Valid headers are: ${validHeaders.join(', ')}`,
        )
      }
      if (!allowedHeaders.includes(trimmedHeader)) {
        throw new Error(
          `Unexpected header found: ${trimmedHeader}. Valid headers are: ${validHeaders.join(', ')}`,
        )
      }
      // If it's already transformed, return it as is.
      if (transformedHeaders.includes(trimmedHeader)) {
        return trimmedHeader
      }
      // Otherwise, transform the original header to its shorter form.
      switch (trimmedHeader) {
        case 'Full_Name':
          return 'name'
        case 'Email_Address':
          return 'email'
        case 'Position':
          return 'position'
        case 'Role':
          return 'role'
        default:
          return trimmedHeader
      }
    },
  })

  // Add an id and initialize flags for each user
  for (let index = 0; index < parsed.data.length; index++) {
    const user = parsed.data[index]
    user.id = index.toString()
    user.createStatus = 'pending'
    user.processing = false
  }

  // Normalize the role value and validate it
  for (const user of parsed.data) {
    const lowerCaseRole = user?.role?.toLowerCase()
    if (lowerCaseRole === 'owner') {
      user.role = 'OWNER'
    } else if (lowerCaseRole === 'admin') {
      user.role = 'ADMIN'
    } else if (lowerCaseRole === 'editor') {
      user.role = 'EDITOR'
    } else if (lowerCaseRole === 'member') {
      user.role = 'MEMBER'
    } else {
      throw new Error(
        `Error parsing CSV: Invalid role ${user.role} for ${user.email}`,
      )
    }
  }

  // Validate email addresses
  for (const user of parsed.data) {
    if (!user.email || !isEmailValid(user.email)) {
      throw new Error(
        `Error parsing CSV: Invalid email address ${user.email} for ${user.name}`,
      )
    }
  }

  // Check for duplicate email addresses
  const emailSet = new Set<string>()
  for (const user of parsed.data) {
    if (emailSet.has(user.email)) {
      throw new Error(
        `Error parsing CSV: Duplicate email address ${user.email} for ${user.name}`,
      )
    }
    emailSet.add(user.email)
  }

  // If everything passed, return the parsed data
  return parsed.data
}
