import { FC } from 'react'

import type {
  FindUserTableUserQuery,
  FindUserTableUserQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import MemberManagement from 'src/components/MemberManagement/MemberManagement'

export const QUERY = gql`
  query FindUserTableUserQuery {
    memberships: membershipsByClient {
      id
      role
      clientId
      memberPositions {
        id
        name
      }
      isActive
      updatedAt
      deactivatedAt
      reportsTo {
        id
        user {
          name
        }
      }
      user {
        id
        name
        email
        phone
        position
        avatarUrl
        invitedAt
        invitedBy
        status
        cognitoUserStatus
        role
        userAgreementAccepted
        avatarStorageObject {
          id
          downloadUrl
        }
        cognitoHasMFAEnabled
      }
      membershipGroups {
        membershipGroup {
          name
          id
        }
      }
    }

    memberPositions: memberPositions {
      id
      name
    }

    membershipGroups: membershipGroupsByClient {
      id
      name
      parentGroup {
        id
        name
      }
      childGroup {
        id
        name
      }
    }
  }
`

type CellProps = CellSuccessProps<
  FindUserTableUserQuery,
  FindUserTableUserQueryVariables
> & {
  fullsize?: boolean
  hideIcons?: boolean
}

export type MembershipGroup = CellProps['membershipGroups'][0]
export type Membership = CellProps['memberships'][0]

export const Loading = () => <LoadingSpinner />

export const Empty: FC<CellProps> = ({ fullsize, hideIcons }) => {
  return (
    <MemberManagement
      memberships={[]}
      memberPositions={[]}
      membershipGroups={[]}
      fullsize={fullsize}
      hideIcons={hideIcons}
    />
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<CellProps> = ({
  memberships,
  memberPositions,
  membershipGroups,
  fullsize,
  hideIcons,
}) => {
  return (
    <MemberManagement
      memberships={memberships}
      memberPositions={memberPositions}
      membershipGroups={membershipGroups}
      fullsize={fullsize}
      hideIcons={hideIcons}
    />
  )
}
