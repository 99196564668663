import React, { type FC, useCallback, useRef, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import type { GridApi } from 'ag-grid-community'
import { MuiFileInput } from 'mui-file-input'

import Button from 'src/components/Library/Button'
import {
  QUERY as FIND_USER_TABLE_USER_QUERY,
  type Membership,
} from 'src/components/MemberManagementCell/MemberManagementCell'

import Modal from '../../Modal/Modal'
import { useUpsertBulkUsersWithMembership } from '../hooks/useUpsertBulkUsersWithMembership'

import BulkUserCreateFinalStepContent from './BulkUserCreateFinalStepContent'
import { parseCsvData } from './parseCsvData'
interface BulkUserCreateModalProps {
  isOpen: boolean
  onClose(): void
  memberships: Membership[]
}

export type BulkCreateUser = {
  id: string
  name: string
  email: string
  position: string
  role: string
  createStatus: string
  processing: boolean
}

function BulkUserCreateStepper({
  activeStep,
  setActiveStep,
  onClose,
  memberships,
}) {
  const apolloClient = useApolloClient()
  const gridApiRef = useRef<GridApi<BulkCreateUser> | null>(null)
  const steps = ['Download CSV Template', 'Upload CSV', 'Review and Confirm']
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const handleFileUpload = (newFile) => {
    setCsvFile(newFile)
  }
  const [isParsingData, setIsParsingData] = useState(false)
  const [showParseError, setShowParseError] = useState<string | null>(null)
  const [parsedUserData, setParsedUserData] = useState<BulkCreateUser[] | null>(
    null,
  )
  const [processedPercentage, setProcessedPercentage] = useState<number>(0)
  const [isCreatingUsers, setIsCreatingUsers] = useState(false)
  const [isFinishedCreating, setIsFinishedCreating] = useState(false)
  const [refetchingUsers, setRefetchingUsers] = useState(false)

  const updateRowStatus = (id: string, newStatus: string) => {
    if (gridApiRef.current) {
      const rowNode = gridApiRef.current.getRowNode(id)
      if (rowNode) {
        const updatedData = {
          ...rowNode.data,
          createStatus: newStatus,
        }
        rowNode.updateData(updatedData)
      }
    }
  }

  const { upsertBulkUsersWithMembership, isProcessing, errors } =
    useUpsertBulkUsersWithMembership(
      memberships,
      updateRowStatus,
      setProcessedPercentage,
    )

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [setActiveStep])

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [setActiveStep])

  const handleClose = useCallback(async () => {
    if (activeStep === 2) {
      setRefetchingUsers(true)
      await apolloClient.refetchQueries({
        include: [FIND_USER_TABLE_USER_QUERY],
      })
      setRefetchingUsers(false)
    }
    setShowParseError(null)
    setParsedUserData(null)
    setCsvFile(null)
    setIsCreatingUsers(false)
    setActiveStep(0)
    onClose()
  }, [activeStep, apolloClient, onClose, setActiveStep])

  const handleParseData = useCallback(async () => {
    if (!csvFile) {
      setShowParseError('No file uploaded')
      return
    }
    setIsParsingData(true)
    setShowParseError(null)
    try {
      const data = await parseCsvData(csvFile)
      setParsedUserData(data)
      handleNext()
    } catch (error) {
      setShowParseError(error instanceof Error ? error.message : String(error))
    } finally {
      setIsParsingData(false)
    }
  }, [csvFile, handleNext])

  const processNewUsers = useCallback(async () => {
    setIsCreatingUsers(true)
    await upsertBulkUsersWithMembership(parsedUserData as BulkCreateUser[])
    if (!isProcessing) {
      setIsCreatingUsers(false)
      setIsFinishedCreating(true)
    }
  }, [upsertBulkUsersWithMembership, parsedUserData, isProcessing])

  const onDownloadCSVTemplate = useCallback(() => {
    const link = document.createElement('a')
    link.download = 'TheHub_MemberBulkImportTemplate.csv'
    link.href = '/TheHub_MemberBulkImportTemplate.csv'
    link.click()
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, _index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <div className="mx-auto flex h-full grow flex-col">
        {activeStep === 0 && (
          <div className="mx-auto flex max-w-3xl flex-col items-center justify-center space-y-6 text-center">
            <p className="pt-10 text-lg text-gray-800">
              Please download the CSV template and fill in the details of the
              users you want to create.
            </p>
            <p className="text-md font-bold text-gray-800">
              Required User Data: Full Name | Email | Position | Role
            </p>
            <p className="text-md text-gray-800">
              When you have filled in the template, proceed to step 2.
            </p>
            <div className="py-6">
              <Button
                variant="contained"
                fullWidth={false}
                startIcon={<FileDownloadIcon />}
                onClick={onDownloadCSVTemplate}
              >
                Download CSV Template File
              </Button>
            </div>
            <div className="w-full space-y-2 rounded-lg bg-gray-100/50 p-4">
              <p className="text-md text-gray-800">Available Role Options:</p>
              <p className="text-gray-600">Owner | Admin | Editor | Member</p>
            </div>
            <div className="rounded-lg bg-blue-50 p-4 text-blue-500">
              <p className="text-sm">
                Note: Most users will be Members unless they require elevated
                permissions with the Admin or Editor roles.
              </p>
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className="mx-auto flex h-full max-w-3xl flex-col">
            <div className="flex flex-col items-center justify-center gap-2 pt-10">
              <Typography variant="h6" gutterBottom className="max-w-[600px]">
                Please upload the updated CSV file you downloaded in step 1.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className="max-w-[600px]"
              >
                Once uploaded, a preview will be available for you in step 3.
              </Typography>
            </div>

            <div className="flex grow items-center justify-center gap-2 py-10">
              <Tooltip title="Click To Upload CSV File">
                <MuiFileInput
                  size="small"
                  className="hover: max-w-[300px] cursor-pointer"
                  placeholder="exampleUsers.csv"
                  fullWidth={false}
                  value={csvFile}
                  onChange={handleFileUpload}
                />
              </Tooltip>
              {showParseError ? (
                <Button
                  fullWidth={false}
                  onClick={() => {
                    setCsvFile(null)
                    setShowParseError(null)
                  }}
                  className="p-2 px-4"
                >
                  Reset
                </Button>
              ) : (
                <Button
                  fullWidth={false}
                  onClick={() => {
                    handleParseData()
                  }}
                  loading={isParsingData}
                  disabled={!csvFile || showParseError !== null}
                  className="p-2 px-4"
                >
                  Upload CSV
                </Button>
              )}
            </div>

            {showParseError && (
              <div className="flex justify-center">
                <div className="rounded-md bg-red-50/50 p-4">
                  <div className="text-sm text-red-500">{showParseError}</div>
                  <div>
                    <p className="pt-2 text-center text-sm text-gray-700">
                      Please fix the errors in the csv file and try again.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {activeStep === 2 && (
          <BulkUserCreateFinalStepContent
            isCreatingUsers={isCreatingUsers}
            processedPercentage={processedPercentage}
            isFinishedCreating={isFinishedCreating}
            parsedUserData={parsedUserData}
            gridApiRef={gridApiRef}
            errors={errors}
          />
        )}
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        {(activeStep === 1 || activeStep === 2) && !isFinishedCreating && (
          <Button
            disabled={activeStep === 0 || showParseError !== null}
            onClick={handleBack}
            sx={{ mr: 1 }}
            fullWidth={false}
            color="inherit"
          >
            Back
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {isFinishedCreating ? (
          <Button
            fullWidth={false}
            onClick={handleClose}
            disabled={isCreatingUsers}
            loading={refetchingUsers}
          >
            Done
          </Button>
        ) : (
          <Button
            onClick={activeStep === 2 ? processNewUsers : handleNext}
            fullWidth={false}
            disabled={
              showParseError !== null ||
              (activeStep === 1 && !parsedUserData) ||
              (activeStep === 2 && parsedUserData?.length === 0)
            }
            loading={isParsingData || isCreatingUsers}
          >
            {activeStep === steps.length - 1 ? 'Create Users' : 'Next'}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const BulkUserCreateModal: FC<BulkUserCreateModalProps> = ({
  isOpen,
  onClose,
  memberships,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      backDropClickDisable
      childClassName="max-h-[800px]"
    >
      <div className="flex h-full flex-col gap-4 p-6">
        <BulkUserCreateStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onClose={onClose}
          memberships={memberships}
        />
      </div>
    </Modal>
  )
}

export default BulkUserCreateModal
