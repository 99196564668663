import { FC, useEffect, useRef, useState } from 'react'

import {
  CreditCardIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LockOpenIcon,
  MinusCircleIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  PencilIcon,
  PlusCircleIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import {
  PencilIcon as PencilIconSolid,
  UserCircleIcon,
} from '@heroicons/react/24/solid'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import {
  GridRowSelectionModel,
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import { PROD_CLIENT } from 'api/src/common/enums'
import { isNil } from 'ramda'

import { navigate, routes } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import { useActivateMember } from 'src/components/MemberManagement/hooks/useActivateMember'
import { useDeactivateMember } from 'src/components/MemberManagement/hooks/useDeactivateMember'
import InviteModal from 'src/components/MemberManagement/InviteModal'
import {
  mapUsersData,
  UserData,
} from 'src/components/MemberManagement/MemberManagement.mapper'
import MemberManagementForm, {
  MemberPositionData,
} from 'src/components/MemberManagement/MemberManagementForm'
import Statistic, {
  CardColors,
} from 'src/components/MemberManagement/Statistic'
import {
  Membership,
  MembershipGroup,
} from 'src/components/MemberManagementCell'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { useAuth } from 'src/Providers'

import BulkUserCreateModal from './BulkUserCreate/BulkUserCreateModal'
import ResetMfaModal from './ResetMfaModal'

enum CreationMode {
  SINGLE_CREATE = 0,
  BULK_CREATE = 1,
}

interface MemberManagementProps {
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
  memberPositions: MemberPositionData[]
  fullsize?: boolean
  hideIcons?: boolean
}

const MemberManagement: FC<MemberManagementProps> = ({
  memberships,
  membershipGroups,
  memberPositions,
  fullsize = true,
  hideIcons = false,
}) => {
  const { currentUser } = useAuth()
  const [userData, setUserData] = useState(mapUsersData(memberships))
  const [member, setMember] = useState(null)
  const [createMemberModalOpen, setCreateMemberModalOpen] = useState(false)
  const [bulkUserCreateModalOpen, setBulkUserCreateModalOpen] = useState(false)
  const [filterTab, setFilterTab] = useState('ALL')
  const [filteredUserData, setUserFilteredData] = useState(userData)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [resetMfaModalOpen, setResetMfaModalOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<UserData[]>([])
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const { deactivateMember } = useDeactivateMember()
  const { activateMember } = useActivateMember()
  const confirm = useConfirm()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    email: false,
  } as Record<GridColDef['field'], boolean>)

  const isSupportMode =
    currentUser.isClientAlias && currentUser.userData.role !== 'SUPERADMIN'

  const clientId = currentUser.membershipData.clientId
  const isStafflink = clientId === PROD_CLIENT.STAFFLINK
  const isOwner = currentUser.membershipData.role === 'OWNER'
  const isSuperAdmin = currentUser.userData.role === 'SUPERADMIN'

  useEffect(() => {
    if (memberships) {
      const newUserData = mapUsersData(memberships)
      setUserData(newUserData)
    }
  }, [memberships])
  useEffect(() => {
    // Reset selected users when changing tabs
    setSelectedUsers([])
    setRowSelectionModel([])
  }, [filterTab])

  useEffect(() => {
    if (filterTab === 'ALL') {
      setUserFilteredData(userData.filter((user) => user.status !== 'INACTIVE'))
    } else if (filterTab === 'BILLABLE') {
      setUserFilteredData(
        userData.filter(
          (user) => user.status === 'ACTIVE' || user.status === 'INVITED',
        ),
      )
    } else {
      setUserFilteredData(userData.filter((user) => user.status === filterTab))
    }
  }, [filterTab, userData])

  const deactivateUser = () => {
    if (isSupportMode) {
      toast.error(
        'In Support mode, only Super Admins are allowed to deactivate members',
      )
      return
    }

    confirm({
      title: 'Deactivate Members?',
      description: (
        <Stack spacing={1}>
          <p>Are you sure you want to deactivate these selected members?</p>
          {selectedUsers.map((user) => (
            <p key={user.id}>- {user.name}</p>
          ))}
          <br />
          <p>
            <strong>
              These members will not be able to login to The Hub, and cannot be
              reactivated for 24 hours.
            </strong>
          </p>
        </Stack>
      ),
      confirmationText: 'Deactivate',
      confirmationButtonProps: { className: 'bg-red-500', color: 'error' },
    }).then(async (isConfirmed) => {
      if (!isConfirmed) return
      await deactivateMember({ data: selectedUsers })
        .then(() => {
          setSelectedUsers([])
          toast.success('Members Deactivated')
        })
        .catch((error) => {
          toast.error(error.message)
        })
    })
  }

  const activateUser = () => {
    if (isSupportMode) {
      toast.error(
        'In Support mode, only Super Admins are allowed to activate members',
      )
      return
    }

    confirm({
      title: 'Activate Members?',
      description: (
        <Stack spacing={1}>
          <p>Are you sure you want to activate these selected members</p>
          {selectedUsers.map((user) => (
            <p key={user.id}>- {user.name}</p>
          ))}
        </Stack>
      ),
    }).then(async (isConfirmed) => {
      if (!isConfirmed) return
      await activateMember({ data: selectedUsers })
        .then(() => {
          setSelectedUsers([])
        })
        .catch((error) => {
          toast.error(error.message)
        })
    })
  }

  const resetMemberMFA = () => {
    if (isSupportMode) {
      toast.error(
        'In Support mode, only Super Admins are allowed to reset MFA for members',
      )
      return
    }

    setResetMfaModalOpen(true)
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'User',
      sortable: true,
      minWidth: 125,
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className={'flex flex-row items-center gap-2'}>
            <Avatar alt={params.row.name} src={params.row.avatarUrl} />
            <div className={'flex flex-row'}>
              <p
                className={'text-gray-800'}
                data-testid={`member-info-${params.row.cognitoHasMFAEnabled}-${params.row.name}-${params.row.id}`}
              >
                {params.row.name}
              </p>
            </div>
          </div>
        )
      },
    },
    { field: 'email', flex: 1, headerName: 'Email' },
    isStafflink && {
      field: 'stafflinkRole',
      headerName: 'Stafflink Role',
      sortable: true,
      minWidth: 120,
      flex: 0.5,
      valueGetter: (_value, row) => row.userRole,
      renderCell: (params) => {
        const bgColor =
          params.row.userRole === 'STAFFLINK'
            ? 'bg-blue-100'
            : params.row.userRole === 'SUPERADMIN'
              ? 'bg-red-100'
              : 'bg-gray-100'
        const fontColor =
          params.row.userRole === 'STAFFLINK'
            ? 'text-blue-600'
            : params.row.userRole === 'SUPERADMIN'
              ? 'text-red-400'
              : 'text-gray-600'

        return (
          <div>
            <Chip
              className={bgColor}
              label={
                <p className={`${fontColor} mt-0.5 align-bottom`}>
                  {params.row.userRole}
                </p>
              }
              size={'small'}
            />
          </div>
        )
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      flex: 0.5,
      valueGetter: (_value, row) => row.status,
      renderCell: (params) => {
        const bgColor =
          params.row.status === 'ACTIVE'
            ? 'bg-green-100'
            : params.row.status === 'INVITED'
              ? 'bg-orange-100'
              : params.row.status === 'INACTIVE'
                ? 'bg-gray-100'
                : 'bg-red-100'
        const fontColor =
          params.row.status === 'ACTIVE'
            ? 'text-green-600'
            : params.row.status === 'INVITED'
              ? 'text-orange-600'
              : params.row.status === 'INACTIVE'
                ? 'text-gray-600'
                : 'text-red-600'
        const iconColor =
          params.row.status === 'ACTIVE'
            ? 'bg-green-400'
            : params.row.status === 'INVITED'
              ? 'bg-orange-400'
              : params.row.status === 'INACTIVE'
                ? 'bg-gray-400'
                : 'bg-red-400'
        return (
          <div>
            <Chip
              className={bgColor}
              icon={<div className={`${iconColor} h-2 w-2 rounded-full`}></div>}
              label={
                <p className={`${fontColor} mt-0.5 align-bottom`}>
                  {params.row.status}
                </p>
              }
              size={'small'}
            />
          </div>
        )
      },
    },
    { field: 'role', headerName: 'Role', minWidth: 125, flex: 0.5 },
    {
      field: 'cognitoHasMFAEnabled',
      headerName: 'MFA / SSO',
      type: 'string',
      minWidth: 120,
      sortable: true,
      flex: 0.5,
      valueGetter: (_value, row) => row.cognitoHasMFAEnabled,
      renderCell: (params) => {
        const bgColor =
          params.row.cognitoHasMFAEnabled === true
            ? 'bg-blue-100'
            : params.row.isSSOUser
              ? 'bg-green-100'
              : params.row.cognitoHasMFAEnabled === false
                ? 'bg-orange-100'
                : 'bg-gray-100'
        const fontColor =
          params.row.cognitoHasMFAEnabled === true
            ? 'text-blue-600'
            : params.row.isSSOUser
              ? 'text-green-600'
              : params.row.cognitoHasMFAEnabled === false
                ? 'text-orange-600'
                : 'text-gray-600'

        return (
          <div>
            <Chip
              className={bgColor}
              label={
                <p className={`${fontColor} mt-0.5 align-bottom`}>
                  {params.row.isSSOUser && 'SSO'}
                  {!params.row.isSSOUser &&
                    params.row.cognitoHasMFAEnabled === true &&
                    'MFA'}
                  {!params.row.isSSOUser &&
                    params.row.cognitoHasMFAEnabled === false &&
                    'NO MFA'}
                  {!params.row.isSSOUser &&
                    isNil(params.row.cognitoHasMFAEnabled) &&
                    'NOT SET'}
                </p>
              }
              size={'small'}
            />
          </div>
        )
      },
    },
    {
      field: 'groups',
      headerName: 'Groups',
      sortable: false,
      flex: 2,
      valueGetter: (_value, row) =>
        row.membershipGroups.map((group) => group.membershipGroup.name),
      renderCell: (params) => {
        return (
          <>
            {params.row.membershipGroups.map((group) => (
              <Chip
                key={group.membershipGroup.id}
                className={'bg-gray-100'}
                label={
                  <p className={'text-gray-800'}>
                    {group.membershipGroup.name}
                  </p>
                }
              />
            ))}
          </>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      hideable: false,
      getActions: (params) => [
        <Tooltip
          title={'Edit Member'}
          key={params.id + 'edit'}
          disableInteractive
          arrow
        >
          <div>
            <GridActionsCellItem
              icon={<PencilIconSolid className={'h-5 w-5 stroke-2'} />}
              label="Edit"
              key={params.id + 'edit'}
              onClick={() => {
                setMember(params.row)
                setCreateMemberModalOpen(true)
              }}
            />
          </div>
        </Tooltip>,
        <Tooltip
          title={'View Profile'}
          key={params.id + 'view'}
          disableInteractive
          arrow
        >
          <GridActionsCellItem
            icon={<UserCircleIcon className={'h-6 w-6 stroke-2'} />}
            label="View"
            key={params.id + 'view'}
            onClick={() => {
              navigate(
                routes.informerDirectoryMember({
                  membershipId: params.id as number,
                }),
              )
            }}
          />
        </Tooltip>,
      ],
    },
  ].filter(Boolean) as GridColDef[]

  const checkSomeSelectedUserStatusActive = (selectedUsers: UserData[]) => {
    //Aggreement user is also considered active
    return selectedUsers.some(
      (user) => user.status === 'ACTIVE' || user.status === 'AGREEMENT',
    )
  }
  const checkResetMFABtnDisabled = (selectedUsers: UserData[]) => {
    //if not every one has MFA, disable the button
    return !selectedUsers.every(
      (user) => user.cognitoHasMFAEnabled === true && !user.isSSOUser,
    )
  }

  const SplitCreateMemberButton = () => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)

    const [selectedMode, setSelectedMode] = useState<CreationMode>(
      CreationMode.SINGLE_CREATE,
    )

    const options: Record<CreationMode, string> = {
      [CreationMode.SINGLE_CREATE]: 'Create Member',
      [CreationMode.BULK_CREATE]: 'Bulk Create',
    }

    const handleClick = () => {
      if (selectedMode === CreationMode.SINGLE_CREATE) {
        setCreateMemberModalOpen(true)
      } else if (selectedMode === CreationMode.BULK_CREATE) {
        setBulkUserCreateModalOpen(true)
      }
    }

    const handleMenuItemClick = (
      _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number,
    ) => {
      setSelectedMode(index as CreationMode)
      if (index === CreationMode.BULK_CREATE) {
        setBulkUserCreateModalOpen(true)
      }
      setOpen(false)
    }

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: Event) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
        return
      }

      setOpen(false)
    }
    return (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="User Create Button Group"
        >
          <Button onClick={handleClick}>{options[selectedMode]}</Button>
          <Button
            size="small"
            fullWidth={false}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="user create options"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{ zIndex: 1 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {Object.entries(options).map(([key, option]) => (
                      <MenuItem
                        key={key}
                        selected={Number(key) === selectedMode}
                        onClick={(event) =>
                          handleMenuItemClick(event, Number(key))
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        className={'justify-between border-b-[1px] px-4 py-2'}
      >
        <GridToolbarQuickFilter
          variant={'outlined'}
          data-testid="member-management-search"
          size={'small'}
          className={'h-full'}
        />
        <div className="flex justify-end">
          <Tooltip
            title={
              checkSomeSelectedUserStatusActive(selectedUsers)
                ? 'One or more selected user accounts is already active. The user cannot be re-invited.'
                : 'Send invite to selected users'
            }
          >
            <span>
              <Button
                key={'invite'}
                variant={'text'}
                color={'secondary'}
                startIcon={
                  <PaperAirplaneIcon
                    className={'h-4 w-4 stroke-2 text-gray-600'}
                  />
                }
                fullWidth={false}
                onClick={() => {
                  setInviteModalOpen(true)
                }}
                disabled={
                  selectedUsers.length <= 0 ||
                  checkSomeSelectedUserStatusActive(selectedUsers) ||
                  filterTab === 'INACTIVE'
                }
              >
                Re/Invite
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              checkResetMFABtnDisabled(selectedUsers)
                ? 'Only users with MFA enabled can have their MFA reset.'
                : 'Reset MFA for selected users'
            }
          >
            <div>
              <Button
                key={'resetmfa'}
                variant={'text'}
                color={'secondary'}
                startIcon={
                  <LockOpenIcon className={'h-4 w-4 stroke-2 text-gray-600'} />
                }
                fullWidth={false}
                onClick={resetMemberMFA}
                disabled={
                  selectedUsers.length <= 0 ||
                  filterTab === 'INACTIVE' ||
                  checkResetMFABtnDisabled(selectedUsers)
                }
              >
                Reset MFA
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              filterTab !== 'INACTIVE'
                ? 'Deactivate Selected User'
                : 'Activate Selected User'
            }
          >
            <span>
              <Button
                key={'remove'}
                fullWidth={false}
                variant={'text'}
                color={'secondary'}
                disabled={selectedUsers.length <= 0}
                startIcon={
                  filterTab !== 'INACTIVE' ? (
                    <MinusCircleIcon
                      className={'h-4 w-4 stroke-2 text-gray-600'}
                    />
                  ) : (
                    <PlusCircleIcon
                      className={'h-4 w-4 stroke-2 text-gray-600'}
                    />
                  )
                }
                onClick={() => {
                  if (filterTab === 'INACTIVE') {
                    activateUser()
                  } else {
                    deactivateUser()
                  }
                }}
                buttonDataTestId="deactivate-member-button"
              >
                {filterTab === 'INACTIVE' ? 'Activate' : 'Deactivate'}
              </Button>
            </span>
          </Tooltip>
          <div className={'ml-2'}>
            <SplitCreateMemberButton />
          </div>
        </div>
      </GridToolbarContainer>
    )
  }

  const TotalSeatsText = () => (
    <span className={'flex flex-row flex-wrap items-center gap-1'}>
      <p>Total Seats</p>
      <Tooltip
        title={
          'A seat is a user who has a status of active or invited these users are billable'
        }
        arrow
        placement={'top'}
      >
        <InformationCircleIcon className={'h-4 w-4 stroke-2 text-blue-400'} />
      </Tooltip>
    </span>
  )

  return (
    <>
      {inviteModalOpen && (
        <InviteModal
          isOpen={inviteModalOpen}
          users={selectedUsers}
          onClose={() => setInviteModalOpen(false)}
        />
      )}
      {resetMfaModalOpen && (
        <ResetMfaModal
          isOpen={resetMfaModalOpen}
          users={selectedUsers}
          onClose={() => setResetMfaModalOpen(false)}
        />
      )}
      {createMemberModalOpen && (
        <MemberManagementForm
          member={member}
          members={memberships}
          memberPositionList={memberPositions}
          isOpen={createMemberModalOpen}
          onClose={() => {
            setCreateMemberModalOpen(false)
            setMember(null)
          }}
          groupList={membershipGroups}
        />
      )}
      {bulkUserCreateModalOpen && (
        <BulkUserCreateModal
          isOpen={bulkUserCreateModalOpen}
          onClose={() => setBulkUserCreateModalOpen(false)}
          memberships={memberships}
        />
      )}

      <div
        className={`mx-auto flex w-full flex-col p-4 md:p-10 ${
          fullsize ? 'h-[calc(100vh-120px)]' : 'min-h-[800px]'
        }`}
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-6">
          <Statistic
            tooltip={'Click to view all members who are not inactive'}
            title={'Total Users'}
            data={userData.filter((user) => user.status !== 'INACTIVE').length}
            color={CardColors.gray}
            icon={!hideIcons ? <UserGroupIcon className={'h-6 w-6'} /> : null}
            active={filterTab === 'ALL'}
            onClick={() => {
              setFilterTab('ALL')
            }}
          />
          <Statistic
            tooltip={'Click to view active and invited members'}
            title={<TotalSeatsText />}
            data={
              userData.filter(
                (user) => user.status === 'ACTIVE' || user.status === 'INVITED',
              ).length
            }
            color={CardColors.blue}
            icon={!hideIcons ? <CreditCardIcon className={'h-6 w-6'} /> : null}
            active={filterTab === 'BILLABLE'}
            onClick={() => {
              setFilterTab('BILLABLE')
            }}
          />
          <Statistic
            tooltip={'Click to view only active'}
            title={'Active'}
            data={
              userData.filter(
                (user) =>
                  user.status === 'ACTIVE' || user.status === 'AGREEMENT',
              ).length
            }
            color={CardColors.green}
            icon={!hideIcons ? <UserIcon className={'h-6 w-6'} /> : null}
            active={filterTab === 'ACTIVE'}
            onClick={() => {
              setFilterTab('ACTIVE')
            }}
          />
          <Statistic
            tooltip={'Click to view invited members'}
            title={'Invited'}
            data={userData.filter((user) => user.status === 'INVITED').length}
            color={CardColors.yellow}
            icon={!hideIcons ? <EnvelopeIcon className={'h-6 w-6'} /> : null}
            active={filterTab === 'INVITED'}
            onClick={() => {
              setFilterTab('INVITED')
            }}
          />
          <Statistic
            tooltip={'Click to view created members'}
            title={'Created'}
            data={userData.filter((user) => user.status === 'CREATED').length}
            color={CardColors.red}
            icon={!hideIcons ? <PencilIcon className={'h-6 w-6'} /> : null}
            active={filterTab === 'CREATED'}
            onClick={() => {
              setFilterTab('CREATED')
            }}
          />
          <Statistic
            tooltip={'Click to view inactive members'}
            title={'Inactive'}
            data={userData.filter((user) => user.status === 'INACTIVE').length}
            color={CardColors.gray}
            icon={!hideIcons ? <NoSymbolIcon className={'h-6 w-6'} /> : null}
            active={filterTab === 'INACTIVE'}
            onClick={() => {
              setFilterTab('INACTIVE')
            }}
          />
        </div>
        <div className={'mt-4 h-[400px] grow bg-white'}>
          <DataGrid<(typeof userData)[0]>
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: false,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            slots={{ toolbar: CustomToolbar }}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={filteredUserData}
            checkboxSelection
            onRowDoubleClick={(params) => {
              if (params.row.role === 'OWNER' && (!isOwner || !isSuperAdmin)) {
                toast.error('You do not have permission to edit the owner')
                return
              }
              setMember(params.row)
              setCreateMemberModalOpen(true)
            }}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              setSelectedUsers(
                filteredUserData.filter((user) =>
                  newRowSelectionModel.includes(user.id),
                ),
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

export default MemberManagement
