import React, { useMemo, type FC } from 'react'

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import PendingIcon from '@mui/icons-material/Pending'
import {
  AllCommunityModule,
  ColDef,
  ModuleRegistry,
  themeQuartz,
  type GridApi,
  type GridReadyEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import type { BulkCreateUser } from './BulkUserCreateModal'

const bulkUserGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

interface BulkUserCreateGridProps {
  parsedUsers: BulkCreateUser[]
  gridApiRef?: React.MutableRefObject<GridApi<BulkCreateUser> | null>
}

type customStatusComponentProps = {
  data: BulkCreateUser
}

const customStatusComponent = ({ data }: customStatusComponentProps) => {
  const { createStatus } = data
  if (createStatus === 'processing') {
    return (
      <div className="flex items-center gap-2">
        <AutorenewOutlinedIcon className={'animate-spin text-indigo-500'} />
        <span>Processing</span>
      </div>
    )
  }
  if (createStatus === 'success') {
    return (
      <div className="flex items-center gap-2">
        <CheckCircleIcon className="text-green-500" />
        <span>Success</span>
      </div>
    )
  }
  if (createStatus === 'skipped') {
    return (
      <div className="flex items-center gap-2">
        <PendingIcon className="text-yellow-500" />
        <span>Skipped - Existing</span>
      </div>
    )
  }
  if (createStatus === 'pending')
    return (
      <div className="flex items-center gap-2">
        <PendingIcon className="text-gray-500" />
        <span>Pending</span>
      </div>
    )
  if (createStatus === 'error') {
    return (
      <div className="flex items-center gap-2">
        <ErrorOutlinedIcon className="text-red-500" />
        <span>Error</span>
      </div>
    )
  }
}

const defaultColDef = {
  flex: 1,
  minWidth: 100,
  sortable: true,
  filter: true,
  resizable: true,
}

const BulkUserCreateGrid: FC<BulkUserCreateGridProps> = ({
  parsedUsers,
  gridApiRef,
}) => {
  ModuleRegistry.registerModules([AllCommunityModule])
  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        filter: true,
        resizable: true,
        maxWidth: 200,
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 200,
      },
      {
        headerName: 'Position',
        field: 'position',
        sortable: true,
        filter: true,
        resizable: true,
        maxWidth: 150,
      },
      {
        headerName: 'Role',
        field: 'role',
        sortable: true,
        filter: true,
        resizable: true,
        maxWidth: 150,
      },
      {
        headerName: 'Create Status',
        field: 'createStatus',
        sortable: true,
        filter: true,
        resizable: true,
        cellRenderer: customStatusComponent,
        cellStyle: { display: 'flex', alignItems: 'left' },
        minWidth: 180,
      },
    ],
    [],
  )

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
  }

  return (
    <div className="flex h-[400px] w-full flex-col">
      <AgGridReact
        rowData={parsedUsers}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        suppressServerSideFullWidthLoadingRow={true}
        onGridReady={onGridReady}
        theme={bulkUserGridTheme}
        rowStyle={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        loadThemeGoogleFonts={true}
      />
    </div>
  )
}

export default BulkUserCreateGrid
