import React from 'react'

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import { Alert, AlertTitle, Typography } from '@mui/material'
import type { GridApi } from 'ag-grid-community'

import type { BulkUserImportError } from '../hooks/useUpsertBulkUsersWithMembership'

import BulkUserCreateGrid from './BulkUserCreateGrid'
import type { BulkCreateUser } from './BulkUserCreateModal'

interface BulkUserCreateFinalStepContentProps {
  isCreatingUsers: boolean
  processedPercentage: number
  isFinishedCreating: boolean
  parsedUserData: BulkCreateUser[]
  gridApiRef: React.MutableRefObject<GridApi>
  errors: BulkUserImportError[]
}

const BulkUserCreateFinalStepContent: React.FC<
  BulkUserCreateFinalStepContentProps
> = ({
  isCreatingUsers,
  processedPercentage,
  isFinishedCreating,
  parsedUserData,
  gridApiRef,
  errors,
}) => {
  return (
    <div className="mx-auto flex h-full w-full flex-col">
      {!isCreatingUsers && !isFinishedCreating && <ReviewInstructions />}
      {isCreatingUsers && (
        <ProcessingStatus processedPercentage={processedPercentage} />
      )}
      {isFinishedCreating && errors.length === 0 && <SuccessAlert />}
      {errors.length > 0 && <ErrorsAlert errors={errors} />}
      {parsedUserData && parsedUserData.length > 0 ? (
        <div className="pt-4">
          <BulkUserCreateGrid
            parsedUsers={parsedUserData}
            gridApiRef={gridApiRef}
          />
        </div>
      ) : (
        <NoUsersAlert />
      )}
    </div>
  )
}

const ReviewInstructions: React.FC = () => (
  <div className="flex flex-col items-center justify-center gap-2 pb-4 pt-8">
    <Typography variant="h6" gutterBottom>
      Please review and confirm the users you are about to create.
    </Typography>
    <Typography variant="body1" gutterBottom>
      If the information is all correct, click the &quot;Create Users&quot;
      button to start the creation process.
    </Typography>
  </div>
)

interface ProcessingStatusProps {
  processedPercentage: number
}
const ProcessingStatus: React.FC<ProcessingStatusProps> = ({
  processedPercentage,
}) => (
  <Typography sx={{ mt: 4, mb: 1 }}>
    <AutorenewOutlinedIcon className="mr-1 animate-spin text-indigo-500" />
    Creating Users... {processedPercentage.toFixed(0)}% Complete
  </Typography>
)

const SuccessAlert: React.FC = () => (
  <Alert severity="success" className="mt-4 rounded-lg">
    <AlertTitle>Success</AlertTitle>
    All users have been created successfully, you can close this modal.
  </Alert>
)

const NoUsersAlert: React.FC = () => (
  <div className="mx-auto flex w-full max-w-3xl justify-center">
    <div className="w-full rounded-md bg-red-50/50 p-4">
      <div className="text-center text-sm text-red-500">
        No users to create.
      </div>
      <Typography className="pt-2 text-center text-sm text-gray-700">
        Please upload a CSV file with user data.
      </Typography>
    </div>
  </div>
)

interface ErrorsAlertProps {
  errors: BulkUserImportError[]
}
const ErrorsAlert: React.FC<ErrorsAlertProps> = ({ errors }) => (
  <Alert severity="error" className="mt-4 rounded-lg">
    <AlertTitle className="font-bold">
      {errors.length > 4
        ? 'Too many errors in bulk user creation, stopping processing.'
        : 'Import Errors'}
    </AlertTitle>
    {errors.map((error, index) => (
      <Typography key={index} variant="body1">
        Email: {error.email} - {error.error.message}
      </Typography>
    ))}
  </Alert>
)

export default BulkUserCreateFinalStepContent
